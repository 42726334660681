import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import Layout from '../components/Layout'

const OrganicPage = ({ data, location }) => {
  const page = data.prismicOrganicCannabis.data
  return (
    <Layout>
      <SEO
        title={page.meta_title}
        description={page.meta_description}
        location={location}
      />
      <div className="content">
        <div className="pb-5 pt-32 lg:pt-36">
          <div className="relative">
            <div className="hidden lg:block absolute pin-t pin-l w-1/2 h-full pl-1/48">
              <div className="sticker type-lg lg:type-3xl text-white">
                <div
                  dangerouslySetInnerHTML={{
                    __html: page.section_one_title.html,
                  }}
                />
              </div>
            </div>
            <div className="grid">
              <div className="grid__half--primary lg:hidden">
                <div className="w-3/4">
                  <h2 className="type-xl lg:type-3xl text-white lg:mb-20">
                    {page.section_one_title.text}
                  </h2>
                </div>
              </div>
              <div className="grid__half--secondary rte pt-4">
                <div
                  dangerouslySetInnerHTML={{
                    __html: page.section_one_content.html,
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="lg:pb-12 lg:mt-10">
          <div className="relative">
            <div className="hidden lg:block absolute pin-t pin-l w-1/2 h-full pl-1/48">
              <div className="sticker type-lg lg:type-3xl text-white">
                <div
                  dangerouslySetInnerHTML={{
                    __html: page.section_two_title.html,
                  }}
                />
              </div>
            </div>
            <div className="grid">
              <div className="grid__half--primary lg:hidden">
                <div className="w-3/4">
                  <h2 className="type-xl lg:type-3xl text-white lg:mb-20 ">
                    {page.section_two_title.text}
                  </h2>
                </div>
              </div>
              <div className="grid__half--secondary rte pt-4">
                <div
                  dangerouslySetInnerHTML={{
                    __html: page.section_two_content.html,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="pb-0 sm:pb-8">
          <div className="relative">
            <div className="hidden lg:block absolute pin-t pin-l w-1/2 h-full pl-1/48">
              <div className="sticker type-lg lg:type-3xl text-white">
                <div
                  dangerouslySetInnerHTML={{
                    __html: page.section_three_title.html,
                  }}
                />
              </div>
            </div>
            <div className="grid">
              <div className="grid__half--primary lg:hidden">
                <div className="w-3/4">
                  <h2 className="type-xl lg:type-3xl text-white lg:mb-20 pt-4 ">
                    {page.section_three_title.text}
                  </h2>
                </div>
              </div>
              <div className="grid__half--secondary rte pt-4">
                <div
                  dangerouslySetInnerHTML={{
                    __html: page.section_three_content.html,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="pb-0 lg:pt-10">
          <div className="relative">
            <div className="hidden lg:block absolute pin-t pin-l w-1/2 h-full pl-1/48">
              <div className="sticker type-lg lg:type-3xl text-white">
                <div
                  dangerouslySetInnerHTML={{
                    __html: page.section_four_title.html,
                  }}
                />
              </div>
            </div>
            <div className="grid">
              <div className="grid__half--primary lg:hidden">
                <div className="w-3/4">
                  <h2 className="type-xl lg:type-3xl text-white lg:mb-20 ">
                    {page.section_four_title.text}
                  </h2>
                </div>
              </div>
              <div className="grid__half--secondary rte pt-4">
                <div
                  dangerouslySetInnerHTML={{
                    __html: page.section_four_content.html,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

OrganicPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export const pageOrganicQuery = graphql`
  query {
    prismicOrganicCannabis {
      data {
        meta_title
        meta_description
        section_one_title {
          html
          text
        }
        section_one_content {
          html
          text
        }
        section_two_title {
          html
          text
        }
        section_two_content {
          html
          text
        }
        section_three_title {
          html
          text
        }
        section_three_content {
          html
          text
        }
        section_four_title {
          html
          text
        }
        section_four_content {
          html
          text
        }
      }
    }
  }
`

export default OrganicPage
